const navbarToggler = document.querySelector(".navbar-toggler");
const navbarMenu = document.querySelector(".navbar-container ul");
const navbarLinks = document.querySelectorAll(".navbar-container a");


navbarToggler.addEventListener("click", navbarTogglerClick);

function navbarTogglerClick() {

  navbarToggler.classList.toggle("open-navbar-toggler");
  navbarMenu.classList.toggle("open");

}

navbarLinks.forEach(elem => elem.addEventListener("click", navbarLinkClick));

function navbarLinkClick() {
  navbarLinks.forEach((link) => {
    if (link.classList.contains("active")) {
        link.classList.remove("active")
    }
  })
  this.classList.toggle("active")
  console.log("this", this)
  if (navbarMenu.classList.contains("open")) {

    

    navbarToggler.click();
    // navbarSocialLinks.append(navbarLinks);
  }
}

